<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>Nilai Rujukan Lab</strong>
              </h5>
            </template>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-button variant="primary" @click="pindahInput()"
                  ><CIcon name="cil-plus" /> Tambah Data</b-button
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr />
              </b-col>
            </b-row>

            <b-alert dismissible fade :show="showing" :variant="variant">{{
              msg
            }}</b-alert>

            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Per Halaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group
                  label="Cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                
                   <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Ketik disini untuk mencari ..."
                    ></b-form-input>
                   

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                        >Hapus</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <template #cell(actions)="item">
                    
                    <b-button
                      variant="dark"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Detail Data'"
                      @click="pindahEdit(item.item)"
                      size="sm"
                      ><CIcon name="cil-info" /> {{ item.actions }}</b-button
                    >

                    <b-button
                      variant="danger"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Hapus Data'"
                      v-b-modal.modal-delete
                      @click="datanya = item.item"
                      size="sm"
                      ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                    >
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="5" offset-md="7">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <ModalDelete
        :data_delete="datanya"
        @alertFromChild="triggerAlert($event)"
        @tutupModal="getDatas()"
      />
  </div>
</template>

<script>


// import { useGetAxios } from "@/composables/useAxios.js";
import ModalDelete from "./modal_delete.vue";
export default {
  components: {
    ModalDelete
  },
  name: "nilai_rujukan_lab",
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      is_data: "",
      datanya: "",
      fields: [
          {
            key: "no",
            label: "No",
            class: "text-left",
          },
          {
            key: "nama_sub_tindakan_lab",
            label: "Parameter",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "umur",
            label: "Umur",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "jenis_kelamin_lab",
            label: "Jenis Kelamin",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "actions",
            label: "Actions",
            class: "table-option-2 text-center",
          },
      ],
      items: [
        { no: '-', parameter: '-', nilai_rujukan: '-', umur : '-', jenis_kelamin :'-' }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      // filterOn: [],
      tableBusy: false,

      data_res: null,
      data_err: null,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    filterOn() {
      // console.log(this.fields.map(el => el.key))
      return this.fields.map(el => el.key)
    },
  },
  activated(){
        this.totalRows = this.items.length;
      this.getDatas();
 
    },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getDatas();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    pindahInput(){
        let vm = this
        vm.$router.push({
            path: "/laboratorium/input_nilai_rujukan_lab",
            // query: { id_ba : data.item.id },
        });
    },
    pindahEdit(x){
        let vm = this
        vm.$router.push({
            path: "/laboratorium/detail_nilai_rujukan_lab",
            query: { id : x.ms_nilai_lab_id },
        });
    },
    async getDatas() {
      try {
        this.tableBusy = true;
        let nilai_lab = await this.$axios.post("/ms_nilai_lab/list", {
          halaman: 0,
          jumlah: 1000
        });
        console.log('nilai_lab', nilai_lab)
        let id_puskesmas = this.$store.state.puskesmas_id
        console.log(id_puskesmas, 'id');
        let x = nilai_lab.data;
        if (x.status === 200 && x.message === "sukses") {
          this.items = nilai_lab.data.data;
          for (let i = 0; i < this.items.length; i++) {
            let x = this.items[i];
            x.no = i +1
            x.nilai_min_max = `${x.nilai_minimum} - ${x.nilai_maximum}`
            x.umur = `${x.dari_umur_tahun} tahun ${x.dari_umur_bulan} bulan ${x.dari_umur_hari} hari - ${x.sampai_umur_tahun} tahun ${x.sampai_umur_bulan} bulan ${x.sampai_umur_hari} hari`
          }
          this.totalRows = this.items.length;
        } else {
          throw x;
        }
      } catch (err) {
        if (err.message) {
          this.triggerAlert({
            variant: "danger",
            msg: (err.message).toUpperCase(),
            showing: true,
          });
        } else {
          this.triggerAlert({
            variant: "danger",
            msg: (err).toUpperCase(),
            showing: true,
          });
        }
      } finally {
        this.tableBusy = false;
      }
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
      vm.getDatas()
    },
  },
};
</script>
